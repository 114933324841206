<template>
  <div v-if="!loading_inicial_data">
    <custom-breadcrumb
      :pageTitle="$t('School Structure')"
      :breadcrumb="[]"
      :helpLink="{ name: 'faq-question', params:{
        category: 'School Structure',
        slug:'how-do-i-delete-school-structure',
        id: 24
      } }"
    ></custom-breadcrumb>
    <b-row v-if="!process_not_allowed">
      <b-col cols="12">
        <b-card>
          <h4>{{$t('forms.delete_structure.title')}}</h4>
          <b-alert show variant="danger">
            <div class="alert-body">
          <p>{{$t('forms.delete_structure.description')}}</p>
              <p>{{$t('forms.delete_structure.disclaimer')}}</p>
            </div>
          </b-alert>
          <p>{{$t('forms.delete_structure.question')}}</p>
          <p>{{$t('forms.delete_all.enter_confirmation_text')}}: <b>{{ stablished_confirmation_text}}</b></p>
          <form
            ref="deletestructureform"
            @submit.stop.prevent="handleDeleteStructure"
          >
            <b-form-group
              :state="deleteAllState"
              :label="$t('forms.delete_all.confirmation_text')"
              label-for="delete_all"
            >
              <!-- invalid-feedback="Confirmation text is required" -->
              <b-form-input
                id="delete_all"
                v-model="confirmation_text"
                :state="deleteAllState"
                required
              />

              <span class="mt-1" 
                style="color:#ea5455; font-size: 0.857rem; width:100%;"
                v-if="confirmation_text && validate_confirmation_text"
                >{{$t('forms.delete_all.confirmation_text_error')}}</span>
            </b-form-group>
            <b-button :disabled="confirmation_text && validate_confirmation_text" 
            variant="danger" type="submit">{{$t('forms.delete')}}</b-button>
          </form>
        </b-card>
      </b-col>
    </b-row>
    <b-alert
        v-if="process_not_allowed"
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          {{ $t('raices.process_not_allowed') }}
        </h4>
        <div class="alert-body">
          <p>{{ $t('raices.process_not_allowed_description') }}</p>
        </div>
      </b-alert>
  </div>
  <div v-else class="text-center mt-2 mb-2">
    <b-spinner variant="primary" label="Text Centered" />
  </div>
</template>

<script>
import { deleteSchoolStructure, raicesInitialData } from '@/api/routes'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import {
  BSpinner, BAlert, BRow, BCol, BCard,BButton, BFormGroup, BFormInput

} from 'bootstrap-vue'
export default {
  components:{
    CustomBreadcrumb,
    BSpinner,
    BAlert,
    BRow,
    BCol,
    BCard,BButton, BFormGroup, BFormInput

  },
  data(){
    return {
      loading_inicial_data: true,
      school_id:null, 
      process_not_allowed: false,

      confirmation_text:'',
      deleteAllState:null,


    }
  },
  mounted(){
    this.getData();
  },
  computed:{
    options(){
      let opt = [
        {
          action: "gotohelp",
          icon: "LifeBuoyIcon",
          name: this.$t('Help')
        }
      ]
      return opt
    },
    stablished_confirmation_text(){
      return this.$t('forms.delete_structure.stablished_confirmation_text')
    },
    validate_confirmation_text(){
      let valid_text = true
        if(this.confirmation_text == this.stablished_confirmation_text){
          valid_text = false
      }
      this.deleteAllState = !valid_text
      return valid_text
    },
  },

  methods: {
    checkFormValidity() {
      const form_valid = this.$refs.deletestructureform.checkValidity()
      // console.log(form_valid, "form_valid")

      let valid_text = false
      if(form_valid){
        if(this.confirmation_text == this.stablished_confirmation_text){
          valid_text = true
        }
      }
      this.deleteAllState = valid_text
      return valid_text
    },
    handleDeleteStructure(){
      if (!this.checkFormValidity()) {
        return
      }
      let data = {
        school_id: this.school_id,
      }
      // console.log("handleDeleteStructure", data)
      this.$http.post(deleteSchoolStructure, data).then( response =>{
        // console.log(response)
        this.confirmation_text=''
        this.deleteAllState=null
        if(response.data.status == 200){
          this.makeToast('success', this.$t('Success'), this.$t('forms.delete_structure.success'));
        } else {
          this.makeToast('danger',this.$t('Error'), this.$t('forms.delete_structure.error'));
        }
      }).catch(error => {
        // console.log("Err -> ", error)
          this.makeToast('danger',this.$t('Error'), error);
      })

    },
    async getData(){
      await this.$http.post(raicesInitialData, {}).then( response => {
        if(response.data.status == 200){
          // console.log(response.data)
          this.loading_inicial_data = false
          this.school_id = response.data.school_id
        } else {
          this.process_not_allowed = true
          this.makeToast('danger', this.$t('Error'), response.data.msg);
          this.loading_inicial_data = false
        }
      
      }).catch( error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      }); 
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>
